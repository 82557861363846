import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const animate1 = keyframes`
0% {
     d: path("M673.39 52.74c27.23 12.36 70.08 24.09 87.31 39.61 17.06 15.5 8.59 34.77-45.65 38.11-54.07 3.37-153.91-9.17-241.94-11.16s-164.31 6.52-229.81 4.15-120.09-15.65-172-32.07S-30 55.45 22.32 48.3s206.19-2 289.56-10.2S408.35 8.19 448.35 2s106.83 3.12 146.26 14.28 51.26 24.1 78.78 36.46z")
}
100% {
 d: path("M677.95 50.76c27.23 12.36 70.08 24.09 87.31 39.61 17.06 15.5 8.59 34.77-45.65 38.11-54.07 3.37-153.91-9.17-241.94-11.16s-164.31 6.52-229.81 4.15-120.09-15.65-172-32.07-107-42.94-54.72-50.09 208.63-3.78 292-12 100-18.79 140-25 106.62.83 146.05 12 51.24 24.09 78.76 36.45z")
}
`;
const animate2 = keyframes`
 0% {
 d: path("M534.35 20.52c20.91 11.84 17.2 27.22 64.26 39.37s144.68 21 177.66 32.64 1.07 25.9-44.69 29.64-105.36-3.08-146.1-11.17-62.54-17.32-118.39-16.4-145.83 12-193.2 6.28-52.1-28.39-12.25-38.55 124.25-7.91 158.47-17.51 18.34-31.1 33.1-37.7 60.29 1.6 81.14 13.4z")
}
100% {
d: path("M534.35 20.52c20.91 11.84 17.2 27.22 64.26 39.37s144.68 21 177.66 32.64 1.07 25.9-44.69 29.64-105.36-3.08-146.1-11.17-62.54-17.32-118.39-16.4-145.83 12-193.2 6.28-52.1-28.39-12.25-38.55 133.7-7.45 167.92-17-1.76-28.4 13-35 70.94-1.61 91.79 10.19z")
}
`;
const animate3 = keyframes`
 0% {
 d: path("M384.15 83.94c-10.77.27-5.18-5.92-2.66-12.24s2.25-12.81 12.85-16.47 32.22-4.6 56.22-10.31 16.27-28.81 19.57-26.61 17.77 29.71 35.73 35.59 73.43 2.81 86.37 6.49-16.79 14.2-45.67 19.77-56.84 6.21-73 3.72-20.2-8.14-35.83-7.57-42.77 7.33-53.58 7.63z")
}
100% {
d: path("M379.59 85.92c-10.77.27-5.18-5.92-2.66-12.24S373 60 383.56 56.29s41 2.71 65-3 56.7-33.2 60-31-15 24.1 3 30 63.17 6.4 76.11 10.08-16.79 14.2-45.67 19.77-56.84 6.21-73 3.72-20.2-8.14-35.83-7.57-42.77 7.33-53.58 7.63z")
//d: path("M379.59 85.92c-10.77.27-5.18-5.92-2.66-12.24S373 60 383.56 56.29s41 2.71 65-3 23.7-38.2 27-36 18 29.1 36 35 63.17 6.4 76.11 10.08-16.79 14.2-45.67 19.77-56.84 6.21-73 3.72-20.2-8.14-35.83-7.57-42.77 7.33-53.58 7.63z")
}
`;

const StyledSvg = styled.svg`
  path.path1 {
    animation: ${animate1} 4s alternate ease-in-out infinite;
  }
  path.path2 {
    animation: ${animate2} 4s alternate ease-in-out infinite;
  }
  path.path3 {
    animation: ${animate3} 4s alternate ease-in-out infinite;
  }
`;

export const Blob = ({ className }) => (
  <StyledSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 587.79 64.03">
    <defs>
      <clipPath id="a">
        <path fill="none" d="M-.44.29h588v64h-588z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        className="path1"
        d="M673.39 52.74c27.23 12.36 70.08 24.09 87.31 39.61 17.06 15.5 8.59 34.77-45.65 38.11-54.07 3.37-153.91-9.17-241.94-11.16s-164.31 6.52-229.81 4.15-120.09-15.65-172-32.07S-30 55.45 22.32 48.3s206.19-2 289.56-10.2c88.68 5.19 96.68-29.6 136.68-35.81s111 3 145 18c39.53 11.12 52.31 20.09 79.83 32.45z"
        fill="#7bdcb5"
      />
      <path
        className="path2"
        d="M534.35 20.52c20.91 11.84 17.2 27.22 64.26 39.37s144.68 21 177.66 32.64 1.07 25.9-44.69 29.64-105.36-3.08-146.1-11.17-62.54-17.32-118.39-16.4-145.83 12-193.2 6.28-52.1-28.39-12.25-38.55 124.25-7.91 158.47-17.51 18.34-31.1 33.1-37.7 60.29 1.6 81.14 13.4z"
        fill="#dca27b"
      />
      <path
        className="path3"
        d="M379.59 85.92c-10.77.27-5.18-5.92-2.66-12.24s2.25-12.81 12.85-16.47S422 52.61 446 46.9s13.27-30.81 16.57-28.61S483.34 50 501.3 55.88s73.43 2.81 86.37 6.49-16.79 14.2-45.67 19.77-56.84 6.21-73 3.72-20.2-8.14-35.83-7.57-42.77 7.33-53.58 7.63z"
        fill="#dc7b8f"
      />
    </g>
  </StyledSvg>
);
